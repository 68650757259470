import moment from 'moment';

export const REGEX_UTILS = {
    email: "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])"
}

export const generateProcessId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

export const checkColorStatusChip = (status) => {
    switch (status) {
        case 'FAILED':
            return '#ff5154';
        case 'COMPLETED':
            return '#adf2ad';
        case 'DONE':
            return '#adf2ad';
        case 'done':
            return '#adf2ad';
        case 'SUBMITTED':
            return '#a2c0ff';
        case 'IN-PROGRESS':
            return '#faff7f';
        case 'IN PROGRESS':
            return '#faff7f';
        case 'in progress':
            return '#faff7f';
        case 'PENDING':
            return '#a2c0ff';
        case 'pending':
            return '#a2c0ff';
        case 'APPROVED':
            return '#adf2ad';
        default:
            return '#faff7f';
    }
}

export const parseDateFromUtcToUserTimezone = (stringDate) => {
    const userTimeZone = moment.tz.guess();
    return moment
        .utc(stringDate)
        .clone().tz(userTimeZone)
        .format("MM-DD-YYYY HH:mm A");
}

export const parseDateToUserTimezoneNoTz = (stringDate) => {
    return moment(stringDate)
        .clone()
        .format("MM-DD-YYYY");
}


export const parseDateToUserTimezoneDateOnly = (stringDate) => {
    const userTimeZone = moment.tz.guess();
    return moment(stringDate)
        .clone().tz(userTimeZone)
        .format("MM-DD-YYYY");
}

export const MAILBOXES_DIC_ROUTES = {
    "iwtcustomerservice@solenis.com": "NA",
    "papcustomerservice@solenis.com": "NA",
    "csargentina@solenis.com": "LATAM",
    "csbrasil@solenis.com": "LATAM",
    "cschile@solenis.com": "LATAM",
    "cscolombia@solenis.com": "LATAM",
    "csmexico@solenis.com": "LATAM",
    "ordersnz@solenis.com": "APAC",
    "cs.operationalteam@solenis.com": "EMEA",
}

export const STORAGE_KEYS = {
    USER: "USER",
    TOKEN: "TOKEN",
    LOGGED_IN: "isLoggedIn",
    USER_TYPE: "user_type",
    USER_ID: "user_id",
    TYPE: "type",
    MAILBOXES: "mailboxes",
    LAYOUT: "layout",
    PERMISSIONS: "permissions",
    LAST_LOGIN: "last_login",
    NOTIFICATION_CHECK: "notification_check",
    ORGANIZATION_ID: "organizationId",
}


export function getNonBreakingSpaces(count) {
    return Array(count + 1).join('\u00a0');
}

export const BANNED_UTILS_MAILS = [
    "gmail", "hotmail", "outlook", "aol", "test", "prueba", "mailinator", "yopmail"
]

export const CAPTCHA_ID = "6LeofwUqAAAAAKDtObXka8tu0GrZZwOG6fFyy2T3";

export const parseDateToUserTimezone = (stringDate) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(stringDate)
        .clone().tz(userTimeZone)
        .format("MM-DD-YYYY HH:mm A");
}