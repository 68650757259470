export const STORAGE_KEYS = {
    USER: "USER",
    TOKEN: "TOKEN",
    LOGGED_IN: "isLoggedIn",
    USER_TYPE: "user_type",
    USER_ID: "user_id",
    COMPANY_NAME: "companyName",
    ROLE_ID: "roleId",
    ID: "ID",
    MAILBOXES: "mailboxes",
    NOTIFICATION_CHECK: "notification_check",
    ORGANIZATION_ID: "organizationId",
}

export const API_URLS = {
    FREETIER_RECOVER_PASSWORD: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/recover-password`,
    FREETIER_CREATE_USER: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/create-user-free`,
    FREETIER_CREATE_USER_FIRST_STEP: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/create-user-free-first-step`,
    FREETIER_LOGIN: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/login`,
    FREETIER_LOGOUT: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/logout`,
    FREETIER_CREATE_ORGANIZATION: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/organizations/create-client`,
    FREETIER_INVITE_MEMBERS: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/organizations/create-invitation`,
    FREETIER_USER_CLIENT_INFO: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/organizations/get-user-client-info`,
    FREETIER_ACCEPT_INVITATION_API: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/organizations/accept-invitation-of-organization`,
    FREETIER_VERIFY_EMAIL: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/send-email-verification`,

    FREETIER_CREATE_JOB: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/create-matching-job`,
    FREETIER_GET_JOBS_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/get-matching-jobs`,
    FREETIER_APP_SERVICE_GET_ALL_FILES: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/get-all-files`,
    FREETIER_GET_MATCH_RESULT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/get-matches-result-by-jobid`,
    FREETIER_GET_GUEST_MATCHES: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/get-guest-matches-by-job-id`,
    FREETIER_GET_JOB_BY_ID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mira/get-matching-job-by-id`,

    FREETIER_UPLOAD_FILE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/upload-file`,
    FREETIER_UPDATE_DATA_CONFIG: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/update-data-config`,
    FREETIER_GET_DATA_STORAGE_LIST: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/get-data-with-filters`,
    FREETIER_GET_CSV_API: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/export-order`,
    FREETIER_GET_DATA_DROPDOWNS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/get-data-dropdowns`,
    FREETIER_GET_DAILY_CONSUMPTION: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/auth/get-consumption`,
    FREETIER_GET_CSV_FIRST_RECORDS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/read-first-records-file`,
    FREETIER_GET_DATA_BY_ID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/data/get-process-by-processId`,

    FREETIER_SOP_GET_SOP_TRANSACTIONS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-sop-transactions-with-filters`,
    FREETIER_SOP_GET_PO_JSON: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-po-json`,
    FREETIER_GET_HTML_BODY_EMAIL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-html-body-by-tid`,
    FREETIER_GET_COMPANY_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-company-order-data`,
    FREETIER_GET_EMAIL_ADDITIONAL_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-email-info-by-tid`,
    FREETIER_GET_DROPDOWN_VALUES: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/misc/get-all-dropdowns`,
    FREETIER_UPLOAD_EML_FILE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/upload-order`,
    FREETIER_APPROVE_TID_INDEX: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/approve-by-tid-index`,
    FREETIER_UPDATE_PO_JSON: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-po-json`,
    FREETIER_UPDATE_PO_JSON_BY_INDEX: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-po-json-by-index`,
    FREETIER_UPDATE_PO_JSON_POOLS_BY_INDEX: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-po-json-pools-by-index`,
    FREETIER_UNDO_APPROVE_TID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/undo-approve-by-tid-index`,
    FREETIER_SET_NOT_PO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/not-po-by-tid?transaction_id=`,
    FREETIER_UNDO_NOT_PO_TID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/undo-not-po-by-tid`,
    FREETIER_VALIDATE_NOT_PO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/validate-order-not-po`,
    FREETIER_CREATE_SOP_CONFIG: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/create-sop-configuration`,
    FREETIER_UPDATE_SOP_CONFIG: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-sop-configuration`,
    FREETIER_GET_SOP_CONFIG: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-sop-configurations`,
    FREETIER_GET_MASSIVE_APPROVAL_API: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/async-mass-approve`,
    FREETIER_GET_MASSIVE_APPROVAL_API_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/mass-approve-landing-page`,
    FREETIER_GET_AUDITS_FILTERS_URL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-audits-with-filters`,
    FREETIER_GENERATE_REPORT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/generate-audit-report`,
    FREETIER_DOWNLOAD_CSV_REPORT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/download-CSV-report-pools`,
    FREETIER_ADD_NEW_RECORD: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/add-new-records`,
    FREETIER_DELETE_RECORD: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/delete-record`,
    FREETIER_UPDATE_RECORD: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/update-record`,
    FREETIER_LIST_RECORD: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/list-record`,
    FREETIER_DOWNLOAD_CSV_RECORDS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/download-in-csv`,
    FREETIER_UPLOAD_CSV_RECORDS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/upload-csv`,
    FREEETIER_GET_ATTATCHMENTS_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-file-by-tid-post`,
    FREETIER_REFRESH_API_CALL: `${process.env.REACT_APP_CORE_SOP_URL}/api/v1/sop/matching-api`,
    FREETIER_SAVE_USER_LOCATION: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/auth/save-user-location`,
    FREETIER_GET_USER_LOCATION: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/auth/get-locations-by-tid`,
    FREETIER_TAKE_LOCATION_CONTROL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/auth/take-location-control`,
    FREETIER_CREATE_WORKFLOW_URL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/create-workflow`,
    FREETIER_ORDERS_STATUS_PIECHART: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/stats/get-orders-status-piechart`,
    FREETIER_ORDERS_STATUS_PIECHART_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/stats/get-orders-status-piechart-landing-page`,
    FREETIER_ORDER_UPLOAD_STATUS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-order-upload-status`,
    FREETIER_DOWNLOAD_CONFIRMATION_PDF: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/download-confirmation-pdf`,
    FREETIER_SEARCH_BY_PO_NUMBER: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/search-by-po-number`,
    FREETIER_TRACK_ORDER_API: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/track-order`,
    FREETIER_UPDATE_NOTIFY_TO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-notifyTo`,
    FREETIER_GET_NOTIFY_TO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-notifyTo`,
    FREETIER_SO_STATUS_PIECHART: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/stats/get-orders-so-status-piechart`,
    FREETIER_HEARTBEAT_VALIDATION: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/auth/heartbeat`,
    FREETIER_GET_WELCOME_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/stats/get-welcome-information`,

    FREETIER_SEARCH_BY_PO_NUMBER_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/search-by-po-number`,
    FREETIER_TRACK_ORDER_API_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/track-order`,
    FREETIER_GET_PREFILLED_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/get-contact`,
    FREETIER_TRIAL_GET_LIST_HOME: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-sop-transactions-with-filters-landing-page`,
    FREETIER_UPLOAD_EML_FILE_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/upload-order-from-landing-page`,
    FREETIER_SOP_GET_PO_JSON_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-po-json-landing-page`,
    FREETIER_GET_HTML_BODY_EMAIL_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-html-body-by-tid-landing-page`,
    FREETIER_GET_COMPANY_DATA_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-company-order-data-landing-page`,
    FREETIER_GET_EMAIL_ADDITIONAL_DATA_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-email-info-by-tid-landing-page`,
    FREEETIER_GET_ATTATCHMENTS_DATA_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-file-by-tid-post-landing-page`,
    FREETIER_UPLOAD_MASTER_DATA_FILE_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/records/upload-csv-landing-page`,
    FREETIER_ORDER_UPLOAD_STATUS_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-order-upload-status-from-landing-page`,
    FREETIER_GET_CSV_API_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/export-order-landing-page`,
    FREETIER_UNDO_APPROVE_TID_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/undo-approve-by-tid-index-landing-page`,
    FREETIER_APPROVE_TID_INDEX_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/approve-by-tid-index-landing-page`,
    FREETIER_UPDATE_PO_JSON_BY_INDEX_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-po-json-by-index-landing-page`,
    FREETIER_DOWNLOAD_CONFIRMATION_PDF_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/download-confirmation-pdf-landing-page`,
    FREETIER_GET_NOTIFY_TO_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-notifyTo-landing-page`,
    FREETIER_UPDATE_NOTIFY_TO_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/update-notifyTo-landing-page`,
    FREETIER_GET_AUDITS_TRIAL_PAGE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-audits-with-filters-landing-page`,
    SSSP_REFRESH_ZOR_CASES_TRIAL: `${process.env.REACT_APP_CORE_SOP_URL}/api/v1/sop/matching-api`,
    FREETIER_GET_BLOGS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/blogs/get-blogs`,
    FREETIER_CREATE_BLOGS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/blogs/create-blog`,
    FREETIER_DELETE_BLOGS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/blogs/delete-blog`,
    FREETIER_GET_BLOG_BY_ID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/blogs/get-blog`,
    FREETIER_EDIT_BLOG_BY_ID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/blogs/edit-blog`,
    FREETIER_CREATE_CONTACT_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/create-contact`,
    FREETIER_UNDO_NOT_PO_TID_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/undo-not-po-by-tid-landing-page`,
    FREETIER_VALIDATE_NOT_PO_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/validate-order-not-po-landing-page`,
    FREETIER_SET_NOT_PO_TRIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/not-po-by-tid-landing-page`,
    SSSP_GET_POOLS_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-po-json-pools-structure`,
    FREETIER_CREATE_CONTACT_TRIAL_DOCUMENT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/send-lead-doc`,
    FREETIER_CREATE_WEBINAR_CONTACT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/send-webinar-mail`,
    FREETIER_SEARCH_MATERIAL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/material-finder/search-material-by-text`,
    FREETIER_GET_MATERIAL_SUGGESTIONS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/material-finder/get-sugestions-by-material-id`,
    FREETIER_CREATE_ORGANIZATION_NEW: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/organizations/create-organization`,
    FREETIER_GET_ORGANIZATION_NEW: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/organizations/get-organizations-by-user`,
    FREETIER_GET_WORKFLOWS_PER_USER: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/get-workflows-by-user`,
    FREETIER_CREATE_WORKFLOW: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/create-workflow`,
    FREETIER_WORKFLOW_UPDATE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/update-workflow-config`,
    FREETIER_UPDATE_ORGANIZATION_NEW: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/organizations/update-organization`,
    FREETIER_GET_CHANGE_WORKFLOW_STATUS: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/change-status-workflow`,
    FREETIER_GET_USERS_BY_CLIENT: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/get-users-by-my-client`,
    FREETIER_CHANGE_USER_ROLE: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/asign-role`,
    FREETIER_INVITE_USER_ADMIN: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/invite-user-to-my-org`,
    FREETIER_LOGO_APP: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/organizations/get-organization-logo`,
    FREETIER_SEARCH_COUNTRY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/locations-select/get-countries-select`,
    FREETIER_SEARCH_STATE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/locations-select/get-states-select`,
    FREETIER_SEARCH_CITY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/locations-select/get-cities-select`,
    FREETIER_UPDATE_USER_PERMITS_URL: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/asign-permission`,
    FREETIER_GET_USERS_ALL_MAILBOXES: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/get-mailboxes-list`,
    FREETIER_UPDATE_USER_MAILBOXES: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/asign-mailboxes`,
    FREETIER_REFRESH_API_REGION_CALL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/refresh-po`,
    FREETIER_CONVERT_API_REGION_CALL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/convert-po`,
    FREE_TIER_GET_CHANGELOG_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/changelog/get-changelog-data`,
    FREE_TIER_GET_CHANGELOG_FILES: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/changelog/get-changelog-files`,
    FREE_TIER_UPLOAD_CHANGELOG: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/changelog/create-changelog`,
    FREETIER_CHANGE_NOTIFICATION_CHECKED: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/asign-notify-check`,
    FREETIER_GET_MASTERDATA_LIST: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/get-master-data-list`,
    FREETIER_DOWNLOAD_CSV_REPORT_VAO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/download-CSV-report-vao`,
    FREETIER_DOWNLOAD_CSV_AUDIT_REPORT_VAO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/download-audit-report-vao`,
    FREETIER_GET_MASS_ACCURACY_BY_TEXT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/search-mass-accuracy-by-text`,
    FREETIER_GET_MASS_ACCURACY_LIST_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/get-list`,
    FREETIER_UPDATE_CURRENT_STEPS_MASS_ACCURACY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/edit-mass-accuracy-steps`,
    FREETIER_DOWNLOAD_CSV_MASS_ACCURACY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/download-csv`,
    FREETIER_UPLOAD_CSV_MASS_ACCURACY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/import-records`,
    FREETIER_CREATE_RECORD_MASS_ACCURACY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/create-record`,
    FREETIER_EDIT_RECORD_MASS_ACCURACY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass-accuracy-tracking/edit-record`,
    FREETIER_DOWNLOAD_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/download-csv`,
    FREETIER_UPLOAD_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/import-records`,
    FREETIER_SMS_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/sms-notification`,
    FREETIER_PHONECALL_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/voice-notification`,
    FREETIER_GET_EVENTS_LIST: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/events/get-events`,
    FREETIER_GET_EVENTS_LIST_BY_TID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/events/get-events-by-tid`,
    FREETIER_SEND_PDF_LOGIC: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/send-roi-pdf`,
    FREETIER_SEND_CHECKLIST_DOCUMENT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/send-checklist-form-pdf`,
    FREETIER_SEND_CHECKLIST_DOCUMENT_PROCUREMENT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/contacts/send-checklist-form-procurement-software`,
    FREETIER_GET_INVOICES_LIST: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/invoices-incosistencies/get-list`,
    FREEETIER_GET_ATTATCHMENTS_DATA_INVOICE: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/invoices-incosistencies/get-file-by-tid`,
    FREETIER_GET_TID_INVOICE_INFO: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/invoices-incosistencies/get-invoice-record`,
    FREETIER_GET_TABLE_SUPPLIER_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/get-orders-by-supplier`,
    FREETIER_GET_TID_PARENT_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/get-orders-from-parent-jobs-transaction-id`,
    FREETIER_GET_SHIPPING_TRACKING_DATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/order-status-tracking/track-shipping`,
    FREETIER_GET_INVOICE_REPORT: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/invoices-incosistencies/get-invoice-report-record`,
    FREETIER_GET_MASS_APPROVAL_HISTORY: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/mass_approval_history/get-mass-approvals-history`,
    FREETIER_WORKFLOW_BY_TRANSACTIONID: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/workflows/get-workflow-info-by-transactionid`,
    SSSP_GET_METADATA: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-metadata`,
    FREETIER_GET_SHEETS_NAME: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/sop/get-sheets-for-excel`,
    FREETIER_CHECK_TOKEN_API_DATA: `${process.env.REACT_APP_PRODUCTION_AUTH_URL}/api/v1/auth/check-token`,
    SSSP_GET_ADS_ORDER_TRACKING: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/organizations/get-organization-adds`,

    FREETIER_GET_HTML_BODY_EMAIL_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/get-html-body-by-tid`,
    FREETIER_GET_LIST_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/get-list`,
    FREETIER_GET_PO_JSON_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/get-po-json`,
    FREETIER_GET_PO_FILE_BY_TID_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/get-file-by-tid-post`,
    FREETIER_GET_EMAIL_ADDITIONAL_DATA_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/get-email-info-by-tid`,
    FREETIER_APPROVE_TID_INDEX_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/approve-by-tid`,
    FREETIER_UNDO_APPROVE_TID_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/undo-approve-by-tid`,
    FREETIER_UPDATE_PO_JSON_BY_INDEX_AI_EXTRACTOR: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/ai-extractions/update-po-json-by-index`,
    FREETIER_GET_AUDITS_FILTERS_AI_EXTRACTOR_URL: `${process.env.REACT_APP_PRODUCTION_APP_SERVICE_URL}/api/v1/audit-extractions/get-audits-with-filters`,

    FREETIER_PREPROCESS_CALL: `${process.env.REACT_APP_AIRFLOW_URL}/api/v1/dags/etl_preprocess_dag/dagRuns`,
    FREETIER_ETL_MATCHING_CALL: `${process.env.REACT_APP_AIRFLOW_URL}/api/v1/dags/etl_matching_dag/dagRuns`,

    CONVERSATIONAL_AI_PROCESS_QUESTION: `${process.env.REACT_APP_CONVERSATIONAL_AI_URL}/api/v1/table-qa/prod-extract`

}