import _ from 'lodash';

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    TableContainer, Paper, Table, TableHead, Button, ListItemText,
    TableRow, Grid, TableBody, Pagination, IconButton, Menu, MenuItem,
    TextField, InputAdornment, Chip, Divider, Tooltip, List, ListSubheader
} from '@mui/material';
import Select from 'react-select';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';

import { getUsersByClient, assignNewRole, getCheckToken } from '../../../../../APICalls';
import EmptyDataComponent from '../../UtilsComponents/EmptyDataComponent';
import { parseDateToUserTimezone } from '../../../../../utils/utils';
import { StyledTableRow, StyledTableCell } from '../../DetailsPage/utils/utils';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';
import InviteNewUser from './InviteNewUser';
import { PermissionModal } from './PermissionModal';
import { MailboxesModal } from './MailboxesModal';

const NO_DISPLAY_FIELDS = [
    "identities", "user_metadata", "last_ip"
]

const UserManagement = () => {
    const navigate = useNavigate();
    const [usersData, setUsersData] = useState(null);
    const [currentElementKeys, setCurrentElementKeys] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(null);
    const [tokenValue, setTokenValue] = useState(null);
    const [currentUserRole, setCurrentUserRole] = useState(null);

    const [openUsernameFilter, setOpenUsernameFilter] = useState(false);
    const [usernameAnchorElement, setUsernameAnchorElement] = useState(null);
    const [openUserTypeFilter, setOpenUserTypeFilter] = useState(false);
    const [userTypeAnchorElement, setUserTypeAnchorElement] = useState(null);
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [statusAnchorElement, setStatusAnchorElement] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [username, setUsername] = useState("");
    const [userType, setUserType] = useState(null);
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState(false);
    const [selectMenuHeight, setSelectMenuHeight] = useState('150px');
    const [userPermissions, setUserPermissions] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [showMailboxesModal, setShowMailboxesModal] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState({
        "username": false, "status": false, "userType": false, "mailboxes": false
    });
    const [appliedFiltersValues, setAppliedFiltersValues] = useState({
        "username": "ALL", "status": "ALL", "userType": "ALL", "mailboxes": ["ALL"]
    });

    const [userTokenData, setUserTokenData] = useState(null);

    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            setUserTokenData(data);
        }
    }

    const callAsignRoleAPI = async (tokenValue, userId, role) => {
        await assignNewRole(tokenValue, userId, role);
        setTimeout(() => setAPICallUsers(tokenValue, currentPage), 1500);
    }

    const handleChangeRole = (userId) => (valueRole) => {
        const parseUserId = userId.split('|');
        const currenParsedId = parseUserId[1];
        callAsignRoleAPI(tokenValue, userId, valueRole.value)
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleInviteUser = () => {
        setOpenModal(true);
    }

    const setAPICallUsers = async (tokenData, pageValue = 0, sizeValue = 25) => {
        const getUsersData = await getUsersByClient(tokenData, pageValue, sizeValue);
        const { status, data } = getUsersData;
        if (status === 401) {
            navigate("/login");
            return;
        }
        const { users, total, page, limit } = data
        const testElement = users?.[0];
        const tableKeys = testElement ? Object.keys(testElement) : [];
        const filteredKeys = tableKeys.filter(element => !NO_DISPLAY_FIELDS.includes(element))
        setTotalPages(Math.ceil(total / limit));
        setCurrentPage(page);
        setUsersData(users);
        setFilteredData(users);
        setCurrentElementKeys(filteredKeys);
    }

    const checkColorStatusChip = (value) => {
        if (value) {
            return '#adf2ad';
        } else {
            return '#ff5154';
        }
    }

    const handleAPICallPage = async (actualPage) => {
        /*
        const {
            username, status, userType, mailboxes
        } = appliedFiltersValues

        if (tokenValue) {
            const validateCheck = await getUsersFilters(
                tokenValue, username, status, userType, mailboxes, actualPage, 25
            )

            if (validateCheck.detail) {
                setFilteredData([]);
                return;
            }
            const { data } = validateCheck;
            const { items, page, size, total } = data;
            setTotalPages(Math.ceil(total / size));
            setCurrentPage(page);
            setFilteredData(items);
        }
        */
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        handleAPICallPage(value);
    }

    const handleOpenUsernameFilter = (event) => {
        setUsernameAnchorElement(event.currentTarget);
        setOpenUsernameFilter(true);
    };

    const handleCloseUsernameFilter = () => {
        setUsernameAnchorElement(null);
        setOpenUsernameFilter(false);
    };

    const handleOpenUserTypeFilter = (event) => {
        setUserTypeAnchorElement(event.currentTarget);
        setOpenUserTypeFilter(true);
    };

    const handleCloseUserTypeFilter = () => {
        setUserTypeAnchorElement(null);
        setOpenUserTypeFilter(false);
    };

    const handleOpenStatusFilter = (event) => {
        setStatusAnchorElement(event.currentTarget);
        setOpenStatusFilter(true);
    };

    const handleClosePermissionsModal = () => {
        setShowPermissionsModal(false);
    };

    const handleCloseStatusFilter = () => {
        setStatusAnchorElement(null);
        setOpenStatusFilter(false);
    };

    const handleFilteringUsername = (usernameValue) => {
        if (_.isEmpty(usernameValue)) {
            setAppliedFilters({
                ...appliedFilters,
                "username": false,
            });
            setAppliedFiltersValues({
                ...appliedFiltersValues,
                "username": "ALL"
            });
            return;
        }
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            "username": usernameValue
        });
        setAppliedFilters({
            ...appliedFilters,
            "username": true,
        });
    }

    const handleFilteringUserType = (UserTypeValue) => () => {
        if (_.isEmpty(UserTypeValue)) {
            setAppliedFilters({
                ...appliedFilters,
                "userType": false,
            });
            setAppliedFiltersValues({
                ...appliedFiltersValues,
                "userType": "ALL"
            });
            return;
        }
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            "userType": UserTypeValue
        });
        setAppliedFilters({
            ...appliedFilters,
            "userType": true,
        });
    }

    const handleFilteringStatus = (statusValue) => () => {
        if (_.isEmpty(statusValue)) {
            setAppliedFilters({
                ...appliedFilters,
                "status": false,
            });
            setAppliedFiltersValues({
                ...appliedFiltersValues,
                "status": "ALL"
            });
            return;
        }
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            "status": statusValue
        });
        setAppliedFilters({
            ...appliedFilters,
            "status": true,
        });
    }


    const handleUserManagement = (userInfo) => {
        setUserToUpdate(userInfo)
        setShowPermissionsModal(true)
    }

    const handleMailboxes = (userInfo) => {
        setUserToUpdate(userInfo)
        setShowMailboxesModal(true);
    }

    const handleCloseMailboxesModal = () => {
        setShowMailboxesModal(false);
    }

    const checkFilters = (key) => {
        if (key === "username") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenUsernameFilter}
                            edge="end"
                            color="primary"
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={usernameAnchorElement}
                            open={openUsernameFilter}
                            onClose={handleCloseUsernameFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={((event) => {
                                        handleFilteringUsername(event.target.value)
                                    })}
                                    label="Username Filter"
                                    placeholder='Type and search Username'
                                    variant="outlined"
                                >
                                </TextField>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "userType") {
            return (
                <>
                    <Grid item xs={8}>
                        <IconButton
                            onClick={handleOpenUserTypeFilter}
                            edge="end"
                            color="primary"
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={userTypeAnchorElement}
                            open={openUserTypeFilter}
                            onClose={handleCloseUserTypeFilter}
                        >
                            <MenuItem
                                onClick={handleFilteringUserType("normal")}
                            >
                                <Grid
                                    container
                                    justifyItems="center"
                                >
                                    <PersonIcon />
                                    Normal
                                </Grid>
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                                onClick={handleFilteringUserType("admin")}
                            >
                                <Grid
                                    container
                                    justifyItems="center"
                                >
                                    <AdminPanelSettingsIcon />
                                    Admin
                                </Grid>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "status") {
            return (
                <>
                    <Grid item xs={8}>
                        <IconButton
                            onClick={handleOpenStatusFilter}
                            edge="end"
                            color="primary"
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={statusAnchorElement}
                            open={openStatusFilter}
                            onClose={handleCloseStatusFilter}
                        >
                            <MenuItem
                                onClick={handleFilteringStatus("active")}
                            >
                                <Grid
                                    container
                                    justifyItems="center"
                                >
                                    <TaskAltIcon />
                                    Active
                                </Grid>
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                                onClick={handleFilteringStatus("inactive")}
                            >
                                <Grid
                                    container
                                    justifyItems="center"
                                >
                                    <CloseIcon />
                                    Inactive
                                </Grid>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "mailboxes") {
            return (
                {/*<Grid xs={6}>
                    <IconButton
                        onClick={handleOpenMailboxesFilter}
                        edge="end"
                        color={checkCurrentAppliedFilter(key)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Menu
                        anchorEl={mailboxesAnchorElement}
                        open={openMailboxesFilter}
                        onClose={handleCloseMailboxesFilter}
                        PaperProps={{
                            style: {
                                height: selectMenuHeight,
                                width: '400px'
                            },
                        }}
                    >
                        <MenuItem
                            onClick={(event) => event.preventDefault()}
                            style={{ height: "100%" }}
                        >
                            &#8288;
                            <Grid container>
                                <Grid item xs={11}>
                                    <Select
                                        isMulti
                                        maxMenuHeight={150}
                                        menuPlacement="bottom"
                                        options={mailboxesOptions}
                                        placeholder="Mailbox:"
                                        onChange={handleOnChangeSelect}
                                        style={{ width: '100%' }}
                                        onMenuOpen={checkOnMenuOpen}
                                        onMenuClose={checkOnMenuClose}
                                        value={appliedFiltersValues['mailboxes'] && !_.isNil(appliedFiltersValues['mailboxes'])
                                            ? appliedFiltersValues['mailboxes'].map((element) => {
                                                return { label: element, value: element }
                                            })
                                            : [{ label: "ALL", value: "ALL" }]}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={handleClearFilter("mailboxes")}
                                        edge="end"
                                        color="error"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Grid>
                                        */}
            )
        }
    }


    const getMailboxesDataCall = async (token, mailboxes, regions) => {

        /*const parsedMailboxes = JSON.parse(mailboxes);
        const data = await getMailboxesData(token, parsedMailboxes, regions);
        const { status, payload } = data;
        if (status === "SUCCESS") {
            const { items } = payload;
            if (items) {
                const selectData = items.map((element) => {
                    return element.mailboxSelectValue
                })
                selectData.push({ label: "ALL", value: "ALL" })
                setMailboxesOptions(selectData);
            }
        } else {
            setMailboxesOptions([]);
        }
        */
    }

    const handleClearFilter = (keyFilter) => () => {
        setAppliedFilters({
            ...appliedFilters,
            [keyFilter]: false,
        });
        if (keyFilter === 'mailboxes') {
            setAppliedFiltersValues({
                ...appliedFiltersValues,
                [keyFilter]: ["ALL"],
            });
            return;
        }
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            [keyFilter]: "ALL",
        });
    }

    useEffect(() => {
        validationCallAPI();
    }, []);

    useEffect(() => {
        if (userTokenData) {
            const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
            const userData = userTokenData?.username;
            const userTypeData = userTokenData?.role;
            const mailboxesData = userTokenData?.mailboxes;
            const permissionsData = userTokenData?.permissions;
            const roleData = userTokenData?.role
            setUserPermissions(permissionsData);
            getMailboxesDataCall(tokenData, mailboxesData, []);
            setTokenValue(tokenData);
            setUsername(userData);
            setUserType(userTypeData);
            setCurrentUserRole(roleData);
        }
    }, [userTokenData])

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        if (refreshData) {
            setAPICallUsers(tokenData);
            setRefreshData(false);
        }
    }, [refreshData])

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setAPICallUsers(tokenData, currentPage);
    }, [currentPage]);



    return (
        <Grid
            container
        >
            <Grid
                container
                paddingTop={3}
                justifyContent="center"
            >
                <h2>
                    User Management Information
                </h2>
            </Grid>
            <Grid container justifyContent="end">
                <Grid item xs={1}>
                    <Tooltip
                        title={
                            <b>Invite new user</b>
                        }
                        arrow
                        placement="top"
                    >
                        <IconButton>
                            <AddCircleOutlineIcon
                                fontSize="large"
                                onClick={handleInviteUser}
                                color='black'
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <TableContainer component={Paper}
                style={{
                    alignItems: "center"
                }}>
                <Table aria-label="customized table"
                    style={{
                        alignItems: "center"
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {!_.isEmpty(currentElementKeys)
                                ? currentElementKeys.map((key, index) => {
                                    if (key === "user_id") {
                                        return null;
                                    }
                                    if (key === "app_metadata") {
                                        return (
                                            <StyledTableCell index={index}>
                                                <Grid
                                                    container
                                                    textAlign="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={12}>
                                                        User Role
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        )
                                    }
                                    if (key === 'permissions') {
                                        return null
                                    }
                                    // REGEX for spacing when camelCase
                                    const parsedKey = key.replace("_", ' ');
                                    const firstLetterRegex = /(\b[a-z](?!\s))/g;
                                    const upperFirstLetter = parsedKey.replace(firstLetterRegex, (letter) => letter.toUpperCase());
                                    return (
                                        <StyledTableCell index={index}>
                                            <Grid
                                                container
                                                textAlign="center"
                                                alignItems="center"
                                            >
                                                <Grid item xs={12}>
                                                    {upperFirstLetter}
                                                </Grid>
                                            </Grid>
                                        </StyledTableCell>
                                    )
                                })
                                : null}
                            {(!_.isEmpty(currentElementKeys) && currentUserRole === "admin") && (
                                <>
                                    <StyledTableCell>
                                        <Grid container textAlign="center" alignItems="center">
                                            <Grid item xs={11}>
                                                Permissions
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container textAlign="center" alignItems="center">
                                            <Grid item xs={11}>
                                                Mailboxes
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        style={{
                            width: "100%"
                        }}
                    >
                        {!_.isEmpty(filteredData)
                            ? (filteredData.map((excelValue, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        {currentElementKeys.map((currentKey, innerIndex) => {
                                            if (NO_DISPLAY_FIELDS.includes(currentKey)) {
                                                return null;
                                            }
                                            if (currentKey === "picture") {
                                                return (
                                                    <StyledTableCell index={innerIndex}>
                                                        <Grid container justifyContent="center">
                                                            <Grid item>

                                                                <img
                                                                    src={excelValue[currentKey]}
                                                                    style={{
                                                                        maxWidth: "80%",
                                                                        height: "auto"
                                                                    }}
                                                                    alt={excelValue['username']}
                                                                >
                                                                </img>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                )
                                            }
                                            if (currentKey === "app_metadata") {
                                                return (
                                                    <StyledTableCell style={{ minWidth: "110px" }} index={innerIndex}>

                                                        <Select
                                                            menuPlacement="bottom"
                                                            options={[{ value: 'normal', label: 'normal' }, { value: 'admin', label: 'admin' }]}
                                                            placeholder="User Role"
                                                            style={{ width: "1000px" }}
                                                            onChange={handleChangeRole((excelValue['user_id']))}
                                                            value={!_.isNil(excelValue[currentKey]['role'])
                                                                ? { label: excelValue[currentKey]['role'], value: excelValue[currentKey]['role'] }
                                                                : {}
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                )
                                            }
                                            if (currentKey === "user_id") {
                                                return null;
                                            }
                                            if (["updated_at", "created_at", "last_login"].includes(currentKey)) {
                                                return (
                                                    <StyledTableCell index={innerIndex}>
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                        >
                                                            {parseDateToUserTimezone(excelValue[currentKey])}
                                                        </Grid>
                                                    </StyledTableCell>
                                                )
                                            }
                                            if (currentKey === "email_verified") {
                                                return (
                                                    <StyledTableCell
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                        index={innerIndex}
                                                    >
                                                        <Chip
                                                            style={{
                                                                backgroundColor: checkColorStatusChip(excelValue[currentKey])
                                                            }}
                                                            label={<b>{excelValue[currentKey] ? "YES" : "NO"}</b>}
                                                        />
                                                    </StyledTableCell>
                                                )
                                            }
                                            return (
                                                <StyledTableCell index={innerIndex}>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        {excelValue[currentKey]}
                                                    </Grid>
                                                </StyledTableCell>
                                            )
                                        })}
                                        {
                                            (userType === "admin") && (
                                                <StyledTableCell>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => handleUserManagement(excelValue)}
                                                        >
                                                            Manage
                                                        </Button>
                                                    </Grid>
                                                </StyledTableCell>
                                            )
                                        }
                                        {(userType === "admin") && (
                                            <StyledTableCell>
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                                color: "black"
                                                            }}
                                                        >
                                                            <List
                                                                sx={{ bgcolor: 'background.paper' }}
                                                                subheader={<ListSubheader
                                                                    disableSticky={true}
                                                                >
                                                                    <b>
                                                                        Mailboxes
                                                                    </b>
                                                                </ListSubheader>}
                                                            >
                                                                <Divider sx={{ my: 0.5 }} />
                                                                <ListItemText
                                                                    color="black"
                                                                    primary={!_.isNil(excelValue['app_metadata']['mailboxes']) ? excelValue['app_metadata']['mailboxes'].join("\n") : ""}
                                                                    primaryTypographyProps={{
                                                                        fontSize: 15,
                                                                        fontWeight: 'medium',
                                                                        letterSpacing: 0,
                                                                    }}
                                                                />
                                                            </List>
                                                        </div>
                                                    }
                                                >
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => handleMailboxes(excelValue)}
                                                    >
                                                        MANAGE
                                                    </Button>
                                                </Tooltip>
                                            </StyledTableCell>
                                        )}
                                    </StyledTableRow>

                                )
                            }))
                            : <EmptyDataComponent />}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid
                container
                justifyContent="center"
                paddingTop={3}
                paddingBottom={5}
            >
                {openModal && (
                    <InviteNewUser
                        openModal={openModal}
                        handleCloseModal={handleCloseModal}
                        token={tokenValue}
                    />
                )}
                {<Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />}
                {
                    showPermissionsModal && (
                        <PermissionModal
                            user={userToUpdate}
                            handleShowModal={showPermissionsModal}
                            handleCloseModal={handleClosePermissionsModal}
                            setRefreshData={setRefreshData}
                        />)
                }
                {
                    showMailboxesModal && (
                        <MailboxesModal
                            user={userToUpdate}
                            handleShowModal={showMailboxesModal}
                            handleCloseModal={handleCloseMailboxesModal}
                            setRefreshData={setRefreshData}
                        />)
                }
            </Grid>
        </Grid >

    )
}

export default UserManagement;